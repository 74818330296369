/**
 * The main entry point for the top-context module.
 *
 * The module exports the `TopContext` class as a `default` export.
 * You can import it into your ES6 projects like this:
 *
 * ```ts
 *
 * import TopContext from 'top-context';
 *
 *
 * // ... some code
 *
 * new TopContext(); // initialize TopContext
 *
 * ```
 *
 * @module TopContext
 */
import 'cross-fetch/polyfill';

import { Auth, AuthImpl } from './auth';
import { Logon, LogonImpl } from './logon';
import { EventBus, createEventBus } from './event-bus';
import { StakeholderClient, StakeholderClientImpl } from './stakeholder-client';
import { TrackingHandler, trackingHandlerFactory } from './tracking-handler';
import logFactory, { LogLevels, C9Logger } from 'c9-js-log-client';
import { LoginClient, LoginClientImpl } from './login';
import { SASCI360Tracking, SASCI360TrackingImpl } from './sas-ci360-tracking';

/**
 * AWS and Hippo REST APIs that are exposed for applications to call.
 *
 * @category Settings
 */
export type EndpointConfig = {
    readonly apiAwsHost: string;
    readonly apiAwsBase: string;
    readonly apiGatewayBase: string;
    /**
     * The path to the c9-js-log-api API Gateway endpoint for logging events.
     */
    readonly logger: string;
    readonly jwtRefresh: string;
    readonly disableJwtRefresh?: boolean;
    readonly apiHippoHost: string;
    readonly contentDocuments: string;
    readonly htmlContent: string;
    readonly resourceBundle: string;
    readonly cdnBase: string;
    readonly wso2host: string;
};

/**
 * Site settings generally refer to configuration values specific to the CMS and backend environment the given webpage is running on.
 *
 *  @category Settings
 */
export type SiteConfig = {
    readonly basePath: string;
    readonly brand: string;
    readonly brandAbbrev: string;
    readonly brandLowercase: string;
    readonly defaultRedirect: string;
    readonly dscmHost?: string;
    readonly dscmNumber: string;
    readonly env: string;
    readonly environment: string;
    readonly fakeLocale: string;
    /**
     * The log level that has been configured for this page to use.
     * This value is usally used when instantiating a new instance of the
     * c9-js-log-client so that it obeys the site settings for logging
     * verbosity.
     */
    readonly logLevel: string;
    readonly originalHost: string;
    readonly preview: boolean;
    readonly scope: string;
    readonly sisterHost: string;
};

/**
 * An object with accessors for settings related to AWS endpoints and the HippoCMS site.
 *
 * @category Settings
 */
export type ConfigData = {
    readonly endpointConfig: EndpointConfig;
    readonly siteConfig: SiteConfig;
};

/**
 * This interface exposes methods that obtain site and endpoint configuration for the current site
 *
 * @category Data Accessors
 */
export interface ConfigObject {
    /**
     * Endpoint configuration
     */
    readonly getEndpointConfig: () => EndpointConfig;
    /**
     * Site configuration
     */
    readonly getSiteConfig: () => SiteConfig;
}

/**
 * Information about the current active user on the given session.
 *
 * @category Settings
 */
export type ActiveUser = {
    /**
     * The active user's name.
     */
    readonly name: string;
    /**
     * The active user's primary email address.
     */
    readonly primaryEmail: string;
    /**
     * The active user's primary phone number.
     */
    readonly primaryPhone: string;
    /**
     * The active user's list of roles.
     */
    readonly roleList: Array<string>;
    /**
     * The active user's zip code
     */
    readonly zip?: string;
    /**
     * In most cases, the customerIdList will be returned as a comma delimited string.
     * However the option for this property to be returned as an array of strings is maintained (in case there are edge cases)
     */
    readonly customerIdList: Array<string> | string;
};

/**
 * Information about the user who is currently logged in.
 *
 * @category Settings
 */
export type LogonUser = {
    /**
     * The logged in user name.
     */
    readonly name: string;
    /**
     * Flag that indicates if the logged in user is also the current active user.
     */
    readonly activeUser: boolean;
};

/**
 * The user data namespace.
 *
 * @category Settings
 */
export type UserData = {
    /**
     * An object containing attributes associated to the current active user.
     */
    readonly activeUser: ActiveUser;
    /**
     * An object containing attributes associated to the current logged in user.
     */
    readonly logonUser: LogonUser;
};

/**
 * Exposes helper functions that further describe the user on the page.
 *
 * @category Data Accessors
 */
export interface UserObject {
    /**
     * Helper function to obtain information about the active user.
     */
    readonly getActiveUser: () => ActiveUser;
    /**
     * Helper function to obtain information about the user who logged on.
     */
    readonly getLogonUser: () => LogonUser;
    /**
     * Determines if the user is logged on or not
     */
    readonly isLoggedOn: boolean;
}

/**
 * The TopContext class encapsulates and exposes "clients" in the module.
 *
 * It serves as an entry point for both the web and node applications for this library.
 *
 * This class is the default export of the module.
 *
 * It will be attached to the browser DOM as `window.topContext` when included on a webpage.
 *
 * If the module is imported into a ES6 NodeJs project, the import syntax would be `const TopContext = require('top-context);`
 *
 * @category Main
 */
export class TopContext {
    /**
     * The configuration object containing AWS and Hippo settings.
     */
    public config: ConfigObject;
    /**
     * The user settings object.
     */
    public user: UserObject;
    /**
     * The client that handles authentication related functionality.
     */
    public auth: Auth;
    /**
     * The client that handles SAS CI 360 related functionality.
     */
    public sasCi360Tracking: SASCI360Tracking;
    /**
     * The pubsub library offered by TopContext for web based messaging.
     */
    public hybridAppEventBus: EventBus;
    /**
     * The stakeholder client that provides abstracted functionality for obtaining stakeholder information from the `c9-stakeholder-service`.
     */
    public stakeholder: StakeholderClient;
    /**
     * The tracking handler client to allow consumers to push behavior to the Google Analyics data layer.
     */
    public trackingHandlerFactory: (applicationId: unknown) => TrackingHandler;
    /**
     * The legacy client for facilitating loging functionality.
     *
     * @deprecated
     */
    public logon: Logon;
    /**
     * The client that facilitates login related functionality via the Nets-eID provider.
     */
    public login: LoginClient;
    /**
     * Flag to determine if the site has been loaded in mock mode (MIT Indblik).
     */
    public mockMode = false;

    /**
     * @private
     */
    public static readonly MODULE_NAME = `${process.env.MODULE_NAME}@${process.env.MODULE_VERSION}`;
    /**
     * The internal logger instance (c9-js-log-client) that clients can use to log important events to Kibana.
     *
     * @private
     */
    private _logger: C9Logger;

    /**
     * Instantiate a new instance of TopContext.
     *
     * All {@linkcode ConfigData} and {@linkcode UserData} will be grouped under the {@linkcode TopContext.config} property.
     *
     * @param {ConfigData} config The configuration object passed in by the caller
     * @param {UserData} user The user data object passed in by the caller
     * @param {string} redirect The redirect URL for the Auth client to use
     */
    constructor(config: ConfigData, user: UserData, redirect?: string) {
        this.mockMode = this.getMockMode(config);

        // if no user roleList array or roleList is ANONYMOUS, user is not loggedOn
        let isLoggedOn = false;
        try {
            isLoggedOn = user.activeUser.roleList[0] !== 'ANONYMOUS';
        } catch {
            isLoggedOn = false;
        }

        this.config = {
            getEndpointConfig: () => {
                return TopContext.createEndpointConfigObject(config.endpointConfig);
            },
            getSiteConfig: () => {
                return TopContext.createSiteConfigObject(config.siteConfig);
            }
        };

        this.user = {
            getActiveUser: () => {
                return TopContext.createActiveUserObject(user.activeUser);
            },
            getLogonUser: () => {
                return TopContext.createLogonUserObject(user.logonUser);
            },
            isLoggedOn: isLoggedOn
        };

        const endpointConfig = this.config.getEndpointConfig();

        let keepSessionAliveUrl = null;
        if (!endpointConfig.disableJwtRefresh) {
            keepSessionAliveUrl = endpointConfig.jwtRefresh;
        }

        this._logger = logFactory(
            endpointConfig.logger,
            process.env.MODULE_NAME,
            LogLevels.info,
            process.env.MODULE_VERSION
        );

        this.sasCi360Tracking = new SASCI360TrackingImpl(this._logger, endpointConfig.apiAwsBase);

        this.auth = new AuthImpl(
            this.sasCi360Tracking,
            this._logger,
            redirect,
            keepSessionAliveUrl,
            window.location.hostname,
            endpointConfig.apiAwsBase
        );

        this.hybridAppEventBus = createEventBus();

        this.trackingHandlerFactory = trackingHandlerFactory;

        this.stakeholder = new StakeholderClientImpl(
            this.hybridAppEventBus,
            config.endpointConfig?.apiAwsHost,
            this._logger
        );

        // deprecated login provider client
        this.logon = new LogonImpl(config.endpointConfig, config.siteConfig, this.auth);

        // c9-neb-login-api - Login Flow 2021 with new provider
        this.login = new LoginClientImpl(
            config.endpointConfig?.apiAwsHost,
            config.siteConfig?.environment,
            this._logger,
            this.auth,
            this.hybridAppEventBus,
            config.siteConfig?.defaultRedirect,
            config.siteConfig?.brandAbbrev,
            redirect,
            config.siteConfig?.env
        );
    }

    /**
     * @private
     *
     * @param endpointConfig
     *
     * @returns
     */
    private static createEndpointConfigObject(endpointConfig: EndpointConfig) {
        return TopContext.assign({}, endpointConfig);
    }

    /**
     * @private
     *
     * @param siteConfig
     *
     * @returns
     */
    private static createSiteConfigObject(siteConfig: SiteConfig) {
        return TopContext.assign({}, siteConfig);
    }

    /**
     * @param activeUser
     *
     * @private
     *
     * @returns
     */
    private static createActiveUserObject(activeUser: ActiveUser) {
        return TopContext.assign({}, activeUser);
    }

    /**
     *
     * @param logonUser
     *
     * @private
     *
     * @returns
     */
    private static createLogonUserObject(logonUser: LogonUser) {
        return TopContext.assign({}, logonUser);
    }

    /**
     * @private
     *
     * @param args
     *
     * @returns
     */
    private static assign(...args: Array<Record<string, unknown>>) {
        if (typeof args[0] === 'undefined' || args[0] == null) {
            throw new TypeError('Cannot convert undefined or null to object');
        }

        const [target, ...rest] = args;

        const to = Object(target);

        rest.forEach((argument) => {
            if (typeof argument !== 'undefined' && argument !== null) {
                for (const key in argument) {
                    if (Object.prototype.hasOwnProperty.call(argument, key)) {
                        to[key] = argument[key];
                    }
                }
            }
        });

        return to;
    }

    /**
     * Obtains the value of the specified query parameter in the URL
     *
     * @category Helpers
     *
     * @param name The name of the query parameter to get
     * @param url The URL to get (or if not supplied, get from the current URL in the window)
     * @returns
     */
    public getQueryParameter(name: string, url = window.location.href): string | null {
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /**
     * Gets all parts in the path supplied
     *
     * @category Helpers
     *
     * @param pathname The path name to get parts for
     * @returns
     */
    public getPathParts(pathname = window.location.pathname): Array<string> {
        return pathname && pathname !== '/' ? pathname.replace(/\/$/, '').split('/').slice(1) : [];
    }

    /**
     * Determines if we are on the mock environment
     *
     * @param config
     *
     * @private
     *
     * @returns
     */
    private getMockMode(config: ConfigData): boolean {
        return config?.siteConfig?.environment === 'insight';
    }
}
