import logFactory, { C9Logger, LogLevels } from 'c9-js-log-client';
import { Auth } from '../auth';
import { Subscription, EventBus } from '../event-bus';
import { BaseClient, BaseClientImpl } from '../base.client';
import { LoginOverlay } from './overlay';
import { LoginPopup } from './popup';
import qsStringify from 'qs/lib/stringify';

/**
 * Properties to be used when invoking the {@linkcode LoginClient.start}
 *
 * @category Login Client
 */
export type LoginStartOptions = {
    /**
     * The login provider to use. Supported values are either `mitid` or `nemid`.
     * This value is optional. If not provided, the login flow will start with a page
     * that allows the user to choose which identity provider to use.
     */
    readonly idp?: string | null;
    /**
     * The URL to redirect to once the login process is completed successfully.
     *
     * The service will validate the target url passed against several criteria.
     * If the target url is not valid, then an error page will be shown by the service in the popup.
     *
     * When this value is specified, the client will ignore the default redirect url even if it exists.
     *
     * If this value is not specified, the client will use the default redirect url if it exists.
     * NOTE: The default redirect URL is set when initializing {@linkcode TopContext.constructor}.
     *
     * If both the target url and the default redirect url does not exist, a fallback redirect url will be used.
     */
    readonly targetUrl?: string | null;
    /**
     * The application name that is calling the login service.
     * This parameter is required for logging purposes to troubleshoot/pinpoint sources of errors.
     *
     * At present, the service will still be callable if at runtime, `appName` is not defined.
     * However this will cause a degradation in our ability to troubleshoot and support issues that may arise.
     *
     * If no `appName` is given, the value of `LoginClientImpl.APP_NAME` (a static class variable)
     * will be used.
     */
    readonly appName: string;
    /**
     * The brand identification
     * Value is used f.eg. to derive the default serviceUrlPath.
     */
    readonly brandAbbrev?: string;
    /**
     * The path to the login service AWS API Gateway Lambda.
     * If this is defined, will override the default value at `LoginCodeImpl.LOGIN_SERVICE_PATH`
     *
     * @link https://github.devops.topdanmark.cloud/c9/c9-login-neb-api
     */
    readonly serviceUrlPath?: string | null;
    /**
     * Override the page (Hippo CMS) log level.
     *
     * This attribute is useful when you are in a production environment that
     * will only log errors but you need to debug an issue and would like
     * to have verbose logs to be sent to Kibana for that purpose.
     */
    readonly logLevel?: LogLevels;
    /**
     * Additional query parameters that should be appended to the service url (optional).
     *
     * Supports an array of values for a single query parameter using "repeat" mode.
     * Will automatically drop null or undefined query parameters.
     *
     * @link https://npmjs.org/package/qs
     *
     * @example
     * ```ts
     * topContext.login.startPopup({
     *      appName: "ln0-test",
     *      serviceUrlPath: "/login-neb/v1/authorize",
     *      extraQueryParameters: {
     *          error: ["testLoginError", "testSystemError"]
     *      }
     * });
     * ```
     * will result in the URL: `https://api.webplatform-hybridapps-staging-01.topdanmark.cloud/login-neb/v1/authorize?appName=ln0-test&environment=dscm289error=testLoginError&error=testSystemError`
     */
    readonly extraQueryParameters?: Record<string, string | Array<string>>;
    /**
     * Troubleshooting flag that will cause desktop clients to open the login popup window as a new tab instead
     * of a popup. This allows the desktop browser to mimic mobile behavior for testing purposes.
     *
     * @default false The browser will open the login flow as a popup
     */
    readonly forceMobilePopupBehavior?: boolean;
    /**
     * Troubleshooting flag that will disabled closing the popup window after login succeeds.
     *
     * @default false The login client will close the login popup after a successful login
     */
    readonly disablePostLoginPopupClose?: boolean;
    /**
     * Troubleshooting flag that will disabled redirect after login succeeds.
     *
     * @default false The login client will redirect the user after a successful login
     */
    readonly disableRedirect?: boolean;

    /**
     * Flag that allows the login popup to persist in test-jwt mode.
     *
     * @default true
     */
    readonly useTestJwt?: boolean;
};

/**
 * The Login client initializes a popover with content from the c9-login-neb-api
 * to facilitate user loging using the new Nets-eID provider.
 *
 * @category Login Client
 */
export interface LoginClient extends BaseClient {
    /**
     * Create a popup overlay with the relevant markup to facilitate the login process
     * using the Nets-eID provider mechanism.
     *
     * Error handling is managed by the lambda providing the service. If any errors happen, the
     * service will render the relevant markup to be displayed to the caller.
     *
     * @function
     *
     * @param {LoginStartOptions} options An object containing the relevant options for popup overlay creation
     */
    readonly startPopup: (options: LoginStartOptions) => void;
    /**
     * Cleans up the modal overlay and window popup when invoked, along with any listeners that may
     * have been created when {@linkcode LoginClient.startPopup} was invoked.
     */
    readonly stopPopup: () => void;
}

/**
 * @internal
 *
 * @extends {LoginClient}
 */
export class LoginClientImpl extends BaseClientImpl implements LoginClient {
    private _apiHost: string;
    private _environment: string;
    private _loginOverlay: LoginOverlay | undefined;
    private _loginPopup: LoginPopup | undefined;
    private _eventBus: EventBus;
    private _auth: Auth;
    private _brandAbbrev: string | undefined;
    private _fallbackRedirectUrl: string;
    private _defaultRedirectUrl: string | undefined | null;
    private _subscriptions: Array<Subscription> = [];
    private _env: string;

    private _boundStopPopupFn: typeof LoginClientImpl.prototype.stopPopup;

    /**
     * The default application name that identifies this client which will be included in logging statements
     */
    public static APP_NAME = 'LoginClient';
    /**
     * The default login service path that will be used to contact the login service (can be overriden before instantiation because it is a static value).
     * If
     */
    public static LOGIN_SERVICE_PATH = '/login-neb/v1/authorize';
    public static LOGIN_COOP_SERVICE_PATH = '/login-neb-co/v1/authorize';
    public static BRAND_ABBRV_COOP = 'co';

    /**
     * @constructor
     *
     * @param apiHost The base path to the target AWS account's API Gateway host or Cloudfront Distribution host fronting an API Gateway host
     * @param environment The DSCM environment the code is running on
     * @param logger An instance of the `c9-js-log-client` that will be used to logged any relevant information for troubleshooting purposes.
     * @param auth The {@linkcode Auth} client that will handle post login actions.
     * for post successful login actions.
     * @param fallbackRedirectUrl The URL to redirect to if there is no `customRedirectUrl` or {@linkcode LoginStartOptions.targetUrl} specified
     * @param defaultRedirectUrl The URL to redirect to after the login operation. This optional parameter can be defined by the developer who owns the webpage that TopContext will be initialized on by passing the `redirect` argument to the {@linkcode TopContext} constructor.
     * @param env The web environment it's running from
     */
    constructor(
        apiHost: string,
        environment: string,
        logger: C9Logger,
        auth: Auth,
        eventBus: EventBus,
        fallbackRedirectUrl: string,
        brandAbbrev?: string,
        defaultRedirectUrl?: string | null,
        env?: string
    ) {
        super(logger);

        this._auth = auth;
        this._eventBus = eventBus;
        this._apiHost = apiHost;
        this._environment = environment;
        this._fallbackRedirectUrl = fallbackRedirectUrl;
        this._defaultRedirectUrl = defaultRedirectUrl;
        this._env = env ?? 'dev';
        this._brandAbbrev = brandAbbrev;
        this._boundStopPopupFn = this.stopPopup.bind(this);

        window.addEventListener('unload', this._boundStopPopupFn);
    }

    public startPopup(options: LoginStartOptions): void {
        this.stopPopup();

        const {
            appName,
            brandAbbrev,
            targetUrl,
            idp,
            serviceUrlPath,
            logLevel,
            extraQueryParameters,
            forceMobilePopupBehavior,
            disablePostLoginPopupClose,
            disableRedirect,
            useTestJwt
        } = options ?? {};

        const appNameForLoginClient = appName
            ? `${LoginClientImpl.APP_NAME}-${appName}`
            : `${LoginClientImpl.APP_NAME}-UNKNOWN`;

        if (!appName) {
            this.createLogEvent(`warn`, `LoginClient.startPopup was called without an appName`, appNameForLoginClient);
        }

        // else use the targetUrl if supplied
        // if not, check for defaultRedirectUrl and use it
        // finally, use fallbackRedirectUrl if nothing can be found
        let finalUrl = targetUrl;
        if (typeof finalUrl === 'undefined' || finalUrl === null || finalUrl.length === 0) {
            if (
                typeof this._defaultRedirectUrl === 'undefined' ||
                this._defaultRedirectUrl === null ||
                this._defaultRedirectUrl.length === 0
            ) {
                finalUrl = this._fallbackRedirectUrl; // config.siteConfig.defaultRedirect
            } else {
                finalUrl = this._defaultRedirectUrl; // topContext redirect param (url ref param)
            }
        }

        const provider: LoginStartOptions['idp'] = idp;

        const finalBrand = brandAbbrev ?? this._brandAbbrev;

        const finalServiceUrlPath =
            serviceUrlPath ??
            (finalBrand === LoginClientImpl.BRAND_ABBRV_COOP
                ? LoginClientImpl.LOGIN_COOP_SERVICE_PATH
                : LoginClientImpl.LOGIN_SERVICE_PATH);

        // if the override log level is not the same as the current logger instance log level,
        // create a new logger instance for this client
        if (logLevel && logLevel !== this.logger.logLevel) {
            this.logger = logFactory(this.logger.logEndpoint, this.logger.appName, logLevel, this.logger.appVersion);
        }

        this.createEntitiesLogEvent(
            `debug`,
            'LoginClient.startPopup initialized',
            {
                targetUrl: finalUrl,
                provider: provider ?? 'N/A',
                appName: appName ?? 'N/A',
                serviceUrl: finalServiceUrlPath
            },
            appNameForLoginClient
        );

        this._showOverlay();
        this._showPopup(
            finalUrl,
            provider,
            appNameForLoginClient,
            finalServiceUrlPath,
            extraQueryParameters,
            forceMobilePopupBehavior,
            disablePostLoginPopupClose,
            disableRedirect,
            useTestJwt
        );
    }

    /**
     * Apply cleanup actions across the login client to avoid memory leaks.
     *
     * @param {LoginClientImpl} this
     */
    public stopPopup(): void {
        this._loginOverlay?.cleanup();
        this._loginOverlay = undefined;
        this._loginPopup?.cleanup();
        this._loginPopup = undefined;
        this._subscriptions.forEach((subscription) => subscription.unsubscribe());
        this._subscriptions = [];
    }

    /**
     * Create the login popup.
     *
     * @private
     *
     * @param targetUrl
     * @param provider
     * @param appName
     * @param serviceUrlPath
     * @param extraQueryParameters
     * @param forceMobilePopupBehavior
     * @param disablePostLoginPopupClose
     * @param disableRedirect
     * @param useTestJwt
     */
    private _showPopup(
        targetUrl: string,
        provider: LoginStartOptions['idp'],
        appName: string,
        serviceUrlPath: string,
        extraQueryParameters?: Record<string, string | Array<string>>,
        forceMobilePopupBehavior = false,
        disablePostLoginPopupClose = false,
        disableRedirect = false,
        useTestJwt = true
    ): void {
        if (!this._loginPopup) {
            const serviceUrl = this._constructServiceUrl(
                targetUrl,
                provider,
                appName,
                serviceUrlPath,
                extraQueryParameters,
                useTestJwt
            );

            this.createLogEvent('info', `[LOGIN START]`, appName);
            this.createLogEvent('debug', `showing login popup with service url: ${serviceUrl}`, appName);

            this._loginPopup = new LoginPopup(serviceUrl, this.logger, appName, this._eventBus);

            this._subscriptions.push(
                this._eventBus.subscribeV2(
                    LoginPopup.EVENTS.CLOSE,
                    () => {
                        this._loginOverlay?.hide();
                    },
                    false
                ),
                this._eventBus.subscribeV2(
                    LoginPopup.EVENTS.FOCUSED,
                    () => {
                        this._loginOverlay?.show();
                    },
                    false
                ),
                this._eventBus.subscribeV2(LoginOverlay.EVENTS.HIDE, () => {
                    // use cleanup here because calling close()
                    // will cause an infinite loop of events
                    this._loginPopup?.cleanup();
                }),
                this._eventBus.subscribeV2(LoginPopup.EVENTS.LOGIN_ABORTED, () => {
                    this._loginOverlay?.hide();
                }),
                this._eventBus.subscribeV2(LoginPopup.EVENTS.LOGIN_SUCCEEDED, (outcome) => {
                    this._loginOverlay?.displayLoadingBars();
                    const { targetUrl: theTargetUrl } = (outcome as unknown as { targetUrl?: string }) ?? {};
                    if (!disablePostLoginPopupClose) {
                        this._loginPopup?.cleanup(LoginPopup.EVENTS.LOGIN_SUCCEEDED);
                    }
                    if (theTargetUrl && !disableRedirect) {
                        this.createLogEvent('debug', `Redirecting to ${theTargetUrl}`, appName);
                        this._auth.onLoginSucceededV2(theTargetUrl);
                    }
                }),
                this._eventBus.subscribeV2(LoginOverlay.EVENTS.REFOCUS, () => {
                    this._loginPopup?.focus();
                })
            );
        }
        this._loginPopup.show(forceMobilePopupBehavior ? '_blank' : undefined);
    }

    /**
     * Create the overlay that hides the page and serves as the background for the login popup
     *
     * @private
     */
    private _showOverlay(): void {
        if (!this._loginOverlay) {
            this._loginOverlay = new LoginOverlay(this._eventBus);
        }
        this._loginOverlay.show();
    }

    /**
     * Convenience method to create the service URL to be used to facilitate the login process.
     *
     * If the target url parameter url provided is not an absolute url (i.e it doesn't start with
     * http or https), "https://" will be appended to it automatically.
     *
     * @private
     *
     * @param targetUrl The target url to redirect to after successful login
     * @param provider The login provider to be used
     * @param appName The app name requesting the login service functionality
     * @param serviceUrlPath The path to the login service
     * @param extraQueryParameters Additional query parameters that should be appended to the URL
     * @param useTestJwt Flag that toggles to persist popup to use test-jwt login form
     * @returns {string} A service URL that can be used to invoke the login service
     */
    private _constructServiceUrl(
        targetUrl: string,
        provider: LoginStartOptions['idp'],
        appName: string,
        serviceUrlPath: string,
        extraQueryParameters?: Record<string, Array<string> | string>,
        useTestJwt?: boolean
    ): string {
        const parameters = {
            // https://github.devops.topdanmark.cloud/c9/c9-login-neb-api/blob/master/src/v1/DOCUMENTATION_V1.md#parameters
            targetUrl:
                typeof targetUrl === 'string' && targetUrl.length > 0
                    ? targetUrl.startsWith('https://') || targetUrl.startsWith('http://')
                        ? targetUrl
                        : `https://${targetUrl}`
                    : undefined,
            environment: this._environment,
            idp: provider,
            topAppName: appName,
            executionId: this.logger.executionId,
            ...extraQueryParameters
        };

        const queryParameters = qsStringify(parameters, {
            addQueryPrefix: true,
            strictNullHandling: true,
            encode: true,
            skipNulls: true,
            arrayFormat: 'repeat'
        });

        return this.getServiceUrl(serviceUrlPath, queryParameters, useTestJwt ?? true);
    }

    /**
     * Returns the popup service url based on which env it's running
     * @param serviceUrlPath
     * @param queryParameters
     * @param useTestJwt
     * @returns
     */
    private getServiceUrl(serviceUrlPath: string, queryParameters: string, useTestJwt = true) {
        const currentHost: string = window.location.host.replace('mit.', 'www.');
        const hostProtocol: string = window.location.protocol;
        const env: string = this._env;
        if (
            (useTestJwt && currentHost && currentHost.indexOf('.topdanmark.cloud') > -1 && env != 'prod') ||
            currentHost.indexOf('localhost') > -1 ||
            currentHost.indexOf('127.0.0.1') > -1
        ) {
            return `${hostProtocol}//${currentHost}/test-jwt-popup/${
                typeof queryParameters !== 'undefined' && queryParameters !== null ? queryParameters : ''
            }`;
        }

        return `${this._apiHost}${serviceUrlPath}${
            typeof queryParameters !== 'undefined' && queryParameters !== null ? queryParameters : ''
        }`;
    }
}
