/**
 * The PushEvent interface describes the interface for pushing tracking related information to thr te
 *
 * @category Tracking Handler Client
 */
export type PushEvent = (event: unknown, data?: Record<string, unknown>) => void;

/**
 * The Tracking Handler client handles push events to the dataLayer object attached to the browser window
 * if it exists.
 *
 * @category Tracking Handler Client
 */
export interface TrackingHandler {
    /**
     * pushes an event to tracking system.
     */
    readonly pushEvent: PushEvent;
}

/**
 *
 * @private
 *
 * @param applicationId
 *
 * @returns
 */
export const trackingHandlerFactory = (applicationId: unknown): TrackingHandler => {
    const trimmedApplicationId = typeof applicationId === 'string' && applicationId.trim();

    if (!trimmedApplicationId) {
        const message = 'applicationId is not a string. Tracking not possible';
        console.warn(message);
        return {
            pushEvent: () => {
                console.warn(message);
            }
        };
    }

    const pushEvent: PushEvent = (event, data) => {
        const trimmedEvent = typeof event === 'string' && event.trim();

        if (!trimmedEvent) {
            console.warn('Event is not a string. Tracking Ignored');
            return;
        }

        const dataLayer: Array<unknown> | undefined = window.dataLayer;
        if (dataLayer) {
            dataLayer.push({
                applicationId: trimmedApplicationId,
                event: trimmedEvent,
                ...(data ?? {})
            });
        }
    };

    return { pushEvent };
};
