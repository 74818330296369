import { C9Logger, LogLevels } from 'c9-js-log-client';
import { BaseClient, BaseClientImpl } from './base.client';
import { TopContext, EndpointConfig } from './top-context';

/**
 * An object containing the user id to be used when initializing SAS CI 360 on the webpage.
 *
 * @category SAS CI360 Tracking Client
 */
export type InitializeOptions = {
    readonly id: unknown;
    readonly [key: string]: unknown;
};

/**
 * The result from calling the /ci360/customer/id endpoint.
 *
 * @category SAS CI360 Tracking Client
 */
export type UserIdResponse = {
    readonly id: string;
};

/**
 * SAS CI360 client that exposes methods to set up the logged in user
 * with the SAS CI360 instance on the page.
 *
 * @category SAS CI360 Tracking Client
 */
export interface SASCI360Tracking extends BaseClient {
    /**
     * Takes the logged in user JWT and extracts the user id out of it by.
     *
     * @param jwt The logged in user `jwtSignedWeb`
     *
     * @returns A promise with the response from ci360 lambda
     */
    readonly getUserId: (jwt: string) => Promise<UserIdResponse | void>;
    /**
     * If the ci360 client is available on the current page window object,
     * this method will invoke it with the "attachIdentity" action and
     * pass the user login id so that it is registered in SAS CI360.
     *
     * If the ci360 client doesn't exist, a warning is logged to Kibana.
     *
     * @param options An object containing the user id of the logged in user to be attached as the user identity in SAS CI360
     */
    readonly initialize: (options: InitializeOptions) => void;
}

/**
 * @internal
 */
export class SASCI360TrackingImpl extends BaseClientImpl implements SASCI360Tracking {
    /**
     * The default application name that identifies this client which will be included in logging statements
     */
    public static APP_NAME = 'SASCI360Tracking';

    private _apiAwsBase: EndpointConfig['apiAwsBase'];

    constructor(logger: C9Logger, apiAwsBase: EndpointConfig['apiAwsBase']) {
        super(logger);
        this._apiAwsBase = apiAwsBase;
    }

    public async getUserId(jwt: string): Promise<UserIdResponse | void> {
        if (typeof window.ci360 === 'function') {
            const response = await window.fetch(this._apiAwsBase + '/ci360/v1/customer/id', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwt}`,
                    'x-top-appname': TopContext.MODULE_NAME
                }
            });
            if (response.ok) {
                return response.json();
            }
            throw new Error('CI360 Customer ID could not be fetched.');
        } else {
            this.createLogEvent(LogLevels.warn, 'SAS CI 360 not available.', SASCI360TrackingImpl.APP_NAME);
        }
    }

    public initialize(options: InitializeOptions): void {
        if (typeof window.ci360 === 'function') {
            window.ci360('attachIdentity', {
                loginId: options.id,
                loginEventType: 'login_id'
            });
        } else {
            this.createLogEvent(LogLevels.warn, 'SAS CI 360 not available.', SASCI360TrackingImpl.APP_NAME);
        }
    }
}
