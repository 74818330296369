import { C9Logger } from 'c9-js-log-client';
import { BaseClient, BaseClientImpl } from './base.client';

/**
 * KeepSessionAlive - This will add event listeners to the document to detect user activity.
 *
 * Every 5 minutes the pingRefreshEndpoint method will be called, and if activity has been detected
 * it will ping the refresh endpoint so that the JWT's timestamp is updated in DynamoDB so that the JWT
 * does not expire.
 *
 * If for some reason the ping fail, it will try again
 * after one minute.
 *
 * @category Keep Session Alive Client
 *
 */
export interface KeepSessionAlive extends BaseClient {
    authToken: string | undefined;
    activityDetected: boolean;
    invalidCredential: boolean;
    ping: () => Promise<void>;
}

/**
 * @internal
 */
export class KeepSessionAliveImpl extends BaseClientImpl implements KeepSessionAlive {
    public static APP_NAME = 'KeepSessionAliveClient';
    /**
     * interval in milliseconds
     *
     * 300000 ms = 5 minutes
     */
    private static REFRESH_INTERVAL = 300000;

    private intervalId: number | undefined;

    private readonly keepAliveEndpoint: string | undefined;

    private _authToken: string | undefined;
    private _activityDetected: boolean;
    private _invalidCredential: boolean;

    private retryAttempts = 0;

    constructor(logger: C9Logger, keepAliveEndpoint?: string, authToken?: string) {
        super(logger);

        this.authToken = authToken;
        this.keepAliveEndpoint = keepAliveEndpoint;

        this._activityDetected = false;
        this._invalidCredential = false;

        if (keepAliveEndpoint) {
            this.setup();
        } else {
            this.logger.warn(
                `cannot run setup because "keep alive" endpoint is not defined!`,
                KeepSessionAliveImpl.APP_NAME
            );
        }
    }

    public get authToken(): string | undefined {
        return this._authToken;
    }

    public set authToken(value: string | undefined) {
        this._authToken = value;
    }

    public get activityDetected(): boolean {
        return this._activityDetected;
    }

    public set activityDetected(value: boolean) {
        this._activityDetected = value;
    }

    public get invalidCredential(): boolean {
        return this._invalidCredential;
    }

    public set invalidCredential(value: boolean) {
        this._invalidCredential = value;
    }

    public async ping(): Promise<void> {
        await this.pingKeepAliveEndpoint();
    }

    private async pingKeepAliveEndpoint(): Promise<void> {
        if (this.invalidCredential) {
            this.logger
                .scaffold({ force: true })
                .debug(`Credentials are invalid, stopping "keep alive" check`, KeepSessionAliveImpl.APP_NAME);
            this.clearTimer();
            return;
        }

        if (!this.authToken) {
            this.logger
                .scaffold({ force: true })
                .warn(`authToken not found, stopping "keep alive" check`, KeepSessionAliveImpl.APP_NAME);
            return;
        }

        if (this.activityDetected || this.retryAttempts) {
            const fetchInit: RequestInit = {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${this.authToken}`,
                    'x-top-appname': 'topContext'
                }
            };

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const response: Response = await fetch(this.keepAliveEndpoint!, fetchInit);

            if (response.status && (response.status === 403 || response.status === 401)) {
                this.invalidCredential = true;

                return;
            }

            if (this.retryAttempts >= 2) {
                this.logger
                    .scaffold({ force: true })
                    .debug(
                        `"keep alive" check has completed the maximum amount of checks (${this.retryAttempts} times)`,
                        KeepSessionAliveImpl.APP_NAME
                    );

                this.clearTimer();

                this.retryAttempts = 0;

                return;
            }

            if (response.status !== 200) {
                this.logger
                    .scaffold({ force: true })
                    .debug(
                        `Received status code ${response.status}. Will now ping "keep alive" endpoint at a 1 minute interval instead`,
                        KeepSessionAliveImpl.APP_NAME
                    );

                this.retryAttempts++;

                window.setTimeout(async () => {
                    await this.pingKeepAliveEndpoint();
                }, 60000);
            }

            if (response.status === 200) {
                this.resetStates();
            }

            this.activityDetected = false;
        }
    }

    private startTimer() {
        this.intervalId = window.setInterval(
            this.pingKeepAliveEndpoint.bind(this),
            KeepSessionAliveImpl.REFRESH_INTERVAL
        );
    }

    private resetStates() {
        this.retryAttempts = 0;
        this.invalidCredential = false;
    }

    private clearTimer() {
        window.clearInterval(this.intervalId);
    }

    private detectActivity(): void {
        this.activityDetected = true;
    }

    private setup() {
        document.addEventListener('mousemove', this.detectActivity.bind(this), false);
        document.addEventListener('mousedown', this.detectActivity.bind(this), false);
        document.addEventListener('keypress', this.detectActivity.bind(this), false);
        document.addEventListener('touchmove', this.detectActivity.bind(this), false);
        this.startTimer();
    }
}
