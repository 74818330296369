/**
 * This type represents all possible data types that can be published as a value.
 *
 * Used by {@linkcode EventBus.publish}
 *
 * @category Event Bus Client
 */
export type PublishValue =
    | null
    | undefined
    | string
    | number
    | boolean
    | Record<string, unknown>
    | (<T>(...args: unknown[]) => T);

/**
 * The subscriber function type defines the callback function that wil be invoked when
 * an event is published. The subscriber callback function type specifies a generic type
 * for the value passed to the callback from the published event. This allows the consumer
 * to declare the type of data the callback will receive if they should want to.
 *
 * @param value The value to be published. Refer to {@linkcode PublishValue} for a list of supported types
 *
 * @category Event Bus Client
 */
export type Subscriber = (value: PublishValue) => void;

/**
 * A subscription is an object returned when calling subscribeV2
 *
 * @category Event Bus Client
 */
export type Subscription = {
    /**
     * Function used to unsubscription this subscription
     */
    readonly unsubscribe: () => void;
    /**
     * the data for the latest event published for this subscription
     */
    readonly latestEvent: unknown;
    /**
     * The eventType for this subscription
     */
    readonly eventType: string;
};

/**
 * The `EventBus` exposes pubsub functionality so that various javascript apps on a single page
 * can subscribe to and/or publish messages based on topics (event names) in a distributed and
 * loosely coupled manner.
 *
 * The initialized `EventBus` can be accessed via {@linkcode TopContext.hybridAppEventBus}
 *
 * @category Event Bus Client
 */
export interface EventBus {
    /**
     * Legacy function to subscribe to an event type for notifications.
     *
     * @deprecated
     *
     * @param eventType typeof the event that you subscribe to
     * @param subscriber subscriber is a function that will be called with the value passed by publisher.
     */
    readonly subscribe: (eventType: string, subscriber: Subscriber) => () => void;

    /**
     *  Function to subscribe to an event type for notifications.
     *
     *  @param eventType typeof the event that you subscribe to
     *  @param subscriber subscriber is a function that will be called with the value passed by publisher.
     *  @param callSubscriberWithLatestEvent should subscriber be called with latest event if there is one. Defaults to true.
     *  @returns The subscription that was created which contains, among other methods, a method to unsusbcribe from the subscription that was made.
     */
    readonly subscribeV2: (
        eventType: string,
        subscriber: Subscriber,
        callSubscriberWithLatestEvent?: boolean
    ) => Subscription;

    /**
     * Function that publishes an event to the event bus so that subscribers to the event name will receive notifications.
     *
     * @param eventType type of the event that you want to notify subscriber
     * @param value value to passed to subscriber, optional
     */
    readonly publish: (eventType: string, value?: PublishValue) => void;
}

/**
 * @internal
 */
export const createEventBus = (): EventBus => {
    const subscribers: { [key: string]: Array<Subscriber> } = {};

    const events: { [key: string]: PublishValue } = {};

    const publish = (eventType: string, value: PublishValue) => {
        if (subscribers[eventType]) {
            subscribers[eventType].forEach((subscriber) => {
                publishForSubscriber(subscriber, eventType, value);
            });
        }
        events[eventType] = value;
    };

    const publishForSubscriber = (subscriber: Subscriber, eventType: string, value: PublishValue) => {
        try {
            subscriber(value);
        } catch (e) {
            console.error('Error in subscriber', { eventType, subscriber });
            console.error(e);
        }
    };

    /**
     * The old subscriber function
     *
     * @deprecated This subscriber method (version 1) is deprecated and should not be directly used
     *
     * @param eventType
     * @param subscriber
     * @returns
     */
    const subscribe = (eventType: string, subscriber: Subscriber) => {
        if (typeof subscriber !== 'function') {
            console.error('subscriber is not a function');
            return function noop() {};
        }
        if (subscribers[eventType]) {
            subscribers[eventType].push(subscriber);
        } else {
            subscribers[eventType] = [subscriber];
        }

        return function unsubscribe() {
            subscribers[eventType].splice(subscribers[eventType].indexOf(subscriber), 1);
        };
    };

    /**
     * The subscriber (version 2) function
     *
     * @param eventType
     * @param subscriber
     * @param callSubscriberWithLatestEvent
     * @returns
     */
    const subscribeV2 = (
        eventType: string,
        subscriber: Subscriber,
        callSubscriberWithLatestEvent = true
    ): Subscription => {
        const unsubscribe = subscribe(eventType, subscriber);
        const latestEvent = events[eventType];

        if (eventType in events) {
            if (callSubscriberWithLatestEvent && typeof subscriber === 'function') {
                publishForSubscriber(subscriber, eventType, latestEvent);
            }
        }

        return {
            unsubscribe,
            eventType,
            latestEvent
        };
    };

    return {
        subscribe,
        publish,
        subscribeV2
    };
};

export default createEventBus;
