import { EndpointConfig, SiteConfig } from './top-context';
import { Auth } from './auth';

/**
 * Properties that will be used to initialize the LogonApp via the TopContext logon client.
 *
 * @category Logon Client
 */
export type InjectConfigProps = {
    /**
     * The target HTML Element selector where the LogonApp will be loaded
     */
    readonly selector: HTMLElement;
    /**
     * Override the default redirect (which is no redirect) url once login is successful
     */
    readonly redirect?: string;
    /**
     * Override the default path to the LogonApp script that will be injected to the target selector
     */
    readonly logonAppScriptSrc?: string;
    readonly codeFileEnabled?: boolean;
};

/**
 * Options for the logon client
 *
 * @deprecated
 *
 * @category Logon Client
 */
export type LogonConfigProps = {
    /**
     * The log level : INFO, WARN, DEBUG, ERROR
     */
    readonly logLevel: string;
    readonly logApiUrl: string;
    readonly authApiBasePath: string;
    readonly environment: string;
    readonly brand: string;
    readonly codeFileEnabled: boolean;
    readonly defaultLoginType: 'KEY_CARD' | 'KEY_FILE';
    readonly nemIdIFrameSrc: string;
    readonly nemIdIFrameSrcCodeFile: string;
    readonly nemIdOriginCodeFile: string;
    readonly nemIdOrigin: string;
    readonly language: string;
    readonly logonAppScriptSrc: string;
    readonly requestIssuer: 'Topdanmark Forsikring';
    readonly rememberUserEnabled: boolean;
    readonly pathLabels: string;
    readonly ttlRememberMe: number;
    readonly configManagementEnabled: boolean;
    readonly certificateVersion: string;
    /**
     * The callback that is invoked upon successful login in the LogonApp
     */
    onLoginSucceeded: (jwt: string, ppsession: string) => void;
};

/**
 * The legacy Logon client initializes the Logon App (a separate app from this module) if
 * it doesn't already exist on the page with the relevant information it needs from top-context
 *
 * @deprecated This login mechanism will be deprecated in favour of the new Nets-eID login flow (see {@linkcode LoginClient})
 *
 * @category Logon Client
 */
export interface Logon {
    readonly config: LogonConfigProps;
    readonly auth: Auth;
    /**
     * The inject function loads the LogonApp based on the properties set
     */
    inject(config: InjectConfigProps): void;
}

/**
 * @internal
 */
export class LogonImpl implements Logon {
    private readonly _config!: LogonConfigProps;
    private readonly _auth!: Auth;

    private redirect: string | undefined = '';

    constructor(endpointConfig: EndpointConfig, siteConfig: SiteConfig, auth: Auth) {
        if (typeof endpointConfig === 'undefined' || typeof siteConfig === 'undefined') {
            return;
        }

        this._auth = auth;

        const nemIdHost = siteConfig.environment === 'prod' ? 'https://applet.danid.dk' : 'https://appletk.danid.dk';

        this._config = {
            logLevel: siteConfig.logLevel,
            logApiUrl: endpointConfig.logger,
            authApiBasePath: endpointConfig.apiAwsHost + '/nemid/v1',
            environment: siteConfig.environment,
            brand: siteConfig.brandLowercase,
            codeFileEnabled: true,
            defaultLoginType: 'KEY_CARD',
            nemIdIFrameSrc: nemIdHost + '/launcher/lmt/1,517,290,956,287',
            nemIdIFrameSrcCodeFile: 'https://codefileclient.danid.dk/?t=1,520,335,729,708',
            nemIdOriginCodeFile: 'https://codefileclient.danid.dk',
            nemIdOrigin: nemIdHost,
            language: 'DA',
            requestIssuer: 'Topdanmark Forsikring',
            rememberUserEnabled: false,
            pathLabels: endpointConfig.resourceBundle + '/logon/',
            ttlRememberMe: 10,
            configManagementEnabled: false,
            certificateVersion: 'v3',
            logonAppScriptSrc:
                'https://www.topdanmark.dk/hybridapps/c9-logon-app/20191210T091551H78f5493/static/js/index.js',
            onLoginSucceeded: async (jwt: string, ppsession: string) => {
                await this.auth.onLoginSucceeded(jwt, ppsession, this.redirect);
            }
        };
    }

    /**
     * Injects the logon app with the given configuration onto the page
     * @param injectConfig
     */
    public inject(injectConfig: InjectConfigProps): void {
        if (typeof injectConfig?.selector === 'undefined') {
            console.warn(
                "Can't inject LogonApp with out a selector. Please provide selector like so: topContext.logon.inject({ selector: document.getElementById('my-selector')})"
            );
        }

        if (typeof this.config === 'undefined') {
            console.warn("Can't inject logon app without a config object");
        }

        const logonAppConfig: LogonConfigProps & InjectConfigProps = {
            ...this.config,
            ...injectConfig
        };

        this.redirect = logonAppConfig.redirect;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(script);

        script.onload = () => {
            if (this.userIsLoggedIn() && logonAppConfig.redirect) {
                window.location.assign(logonAppConfig.redirect);
            } else if (this.userIsLoggedIn()) {
                console.log('Inject LogonApp: User is logged in, but no redirect. Skipping logon app.');
            } else {
                this.startLogonApp(logonAppConfig);
            }
        };

        script.src = logonAppConfig.logonAppScriptSrc ?? '';
    }

    /**
     *
     * @private
     *
     * @param logonAppConfig
     */
    private startLogonApp(logonAppConfig: LogonConfigProps & InjectConfigProps) {
        if (typeof window.LogonApp !== 'undefined') {
            new window.LogonApp(logonAppConfig, logonAppConfig.selector); //NOSONAR
        }
    }

    /**
     *
     * @private
     *
     * @returns
     */
    private userIsLoggedIn(): boolean {
        return typeof this.auth?.getAuthToken() !== 'undefined';
    }

    get config(): LogonConfigProps {
        return this._config;
    }

    get auth(): Auth {
        return this._auth;
    }
}
