import templateBuilder from 'lodash.template';
import { EventBus } from '../event-bus';

/**
 * The LoginOverlay class is an internal class that renders and performs
 * state management for the modal that functions as a page overlay
 * when the login popup is being displayed.
 *
 * @internal
 */
export class LoginOverlay {
    private show_overlay = new CustomEvent('show.overlay', {});
    private hide_overlay = new CustomEvent('hide.overlay', {});
    public static ELEMENT_IDS: Record<string, string> = {
        modalRoot: 'log-ind-modal',
        modalDialog: 'log-ind-modal__dialog',
        modalBodyContent: 'log-ind-modal__bodyContent',
        modalBodyLoadingBars: 'log-ind-modal__bodyLoadingBars',
        modalClose: 'log-ind-modal__button-modalClose',
        modalFocus: 'log-ind-modal__button-modalFocus',
        modalBackdropRoot: 'log-ind-backdrop-modal'
    };

    public static EVENTS = {
        SHOW: '__C9_LOGIN_OVERLAY__SHOW',
        SHOWN: '__C9_LOGIN_OVERLAY__SHOWN',
        HIDE: '__C9_LOGIN_OVERLAY__HIDE',
        HIDDEN: '__C9_LOGIN_OVERLAY__HIDDEN',
        REFOCUS: '__C9_LOGIN_OVERLAY__REFOCUS'
    };

    public static LABELS = {
        HEADER_TEXT: [`Skal vi hjælpe dig med at åbne`, `log ind-boksen igen?`],
        OPEN_LOGIN_POPUP_TEXT: 'Åbn log-ind boks',
        CLOSE_LOGIN_POPUP_TEXT: 'Gå tilbage'
    };

    public static MODAL_TEMPLATE = `
      <div class="modal" role="dialog" aria-hidden="true" id="<%= modalRootId %>">
        <div class="modal-dialog modal-dialog-center" role="document" id="<%= modalDialogId %>" style="display: flex;flex-direction: column;justify-content: center;margin: 0px auto;">
          <div class="topdk-modal__content topdk-modal__content-transparent">
            <div class="topdk-modal__body" id="<%= modalBodyContentId %>">
                <h3>
                    <%= headerText %>
                </h3>
                <div class="cvi-button-group cvi-button-group--stacked">
                    <button class="cvi-button cvi-button--level5" type="button" id="<%= modalFocus %>">
                        <%= openLoginPopupText %>
                    </button>
                    <button class="cvi-button cvi-button--level1" type="button" id="<%= modalClose %>">
                        <%= closeLoginPopupText %>
                    </button>
                </div>
            </div>
            <div class="topdk-modal__body hidden" id="<%= modalBodyLoadingBarsId %>">
                <div class="cvi-loading-dots cvi-loading-dots--abs"/>
            </div>
          </div>
        </div>
      </div>
      `;
    public static MODAL_BACKDROP_TEMPLATE = `<div class="modal-backdrop fade" id="<%= modalBackdropRoot %>" style="display: none;"></div>`;

    private _modalRootElement: HTMLElement | undefined;
    private _modalBackDropRootElement: HTMLElement | undefined;
    private _modalBodyContent: HTMLElement | undefined;
    private _modalBodyLoadingBars: HTMLElement | undefined;
    private _modalRefocusPopupButton: HTMLButtonElement | undefined;
    private _modalClosePopupButton: HTMLButtonElement | undefined;

    private _eventBus: EventBus;

    constructor(eventBus: EventBus) {
        this._eventBus = eventBus;

        const template = templateBuilder(LoginOverlay.MODAL_TEMPLATE)({
            modalRootId: LoginOverlay.ELEMENT_IDS.modalRoot,
            modalDialogId: LoginOverlay.ELEMENT_IDS.modalDialog,
            modalBodyContentId: LoginOverlay.ELEMENT_IDS.modalBodyContent,
            modalBodyLoadingBarsId: LoginOverlay.ELEMENT_IDS.modalBodyLoadingBars,
            modalFocus: LoginOverlay.ELEMENT_IDS.modalFocus,
            modalClose: LoginOverlay.ELEMENT_IDS.modalClose,
            headerText: LoginOverlay.LABELS.HEADER_TEXT.join('<br/>'),
            openLoginPopupText: LoginOverlay.LABELS.OPEN_LOGIN_POPUP_TEXT,
            closeLoginPopupText: LoginOverlay.LABELS.CLOSE_LOGIN_POPUP_TEXT
        });
        const backDropTemplate = templateBuilder(LoginOverlay.MODAL_BACKDROP_TEMPLATE)({
            modalBackdropRoot: LoginOverlay.ELEMENT_IDS.modalBackdropRoot
        });

        const documentBody: HTMLElement = document.body.firstElementChild as HTMLElement;

        if (documentBody && documentBody.innerHTML !== '') {
            const loginModalElement: HTMLDivElement = this.createElementFromHTML(template) as HTMLDivElement;
            const loginModalBackDropElement: HTMLDivElement = this.createElementFromHTML(
                backDropTemplate
            ) as HTMLDivElement;
            loginModalElement.setAttribute('id', LoginOverlay.ELEMENT_IDS.modalContainer);

            documentBody.append(loginModalElement);
            documentBody.append(loginModalBackDropElement);

            this._modalRootElement = loginModalElement;
            this._modalBackDropRootElement = loginModalBackDropElement;
            this._modalBodyContent = this._modalRootElement.querySelector(
                `div#${LoginOverlay.ELEMENT_IDS.modalBodyContent}`
            ) as HTMLElement;
            this._modalBodyLoadingBars = this._modalRootElement.querySelector(
                `div#${LoginOverlay.ELEMENT_IDS.modalBodyLoadingBars}`
            ) as HTMLElement;

            this._modalClosePopupButton = loginModalElement.querySelector(
                `button#${LoginOverlay.ELEMENT_IDS.modalClose}`
            ) as HTMLButtonElement;
            this._modalRefocusPopupButton = loginModalElement.querySelector(
                `button#${LoginOverlay.ELEMENT_IDS.modalFocus}`
            ) as HTMLButtonElement;

            this.addListeners();
        }
    }

    private createElementFromHTML = (htmlString: string) => {
        const div: HTMLDivElement = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild;
    };

    private modelShowEvent = () => {
        this._eventBus.publish(LoginOverlay.EVENTS.SHOW);
    };
    private modelHideEvent = () => {
        this._eventBus.publish(LoginOverlay.EVENTS.HIDE);
    };
    private modalClosePopupButtonClickEvent = (event: MouseEvent) => {
        event.preventDefault();
        this.hide();
    };
    private modalRefocusPopupButtonClickEvent = (event: MouseEvent) => {
        event.preventDefault();
        this._eventBus.publish(LoginOverlay.EVENTS.REFOCUS);
    };

    /**
     * Listens for modal events and publishes them to the outside world
     * via the hybridapp event bus.
     */
    private addListeners(): void {
        this.removeListeners();

        if (this._modalRootElement) {
            this._modalRootElement.addEventListener('show.overlay', this.modelShowEvent);
            this._modalRootElement.addEventListener('hide.overlay', this.modelHideEvent);
        }

        if (this._modalClosePopupButton) {
            this._modalClosePopupButton.addEventListener('click', this.modalClosePopupButtonClickEvent);
        }

        if (this._modalRefocusPopupButton) {
            this._modalRefocusPopupButton.addEventListener('click', this.modalRefocusPopupButtonClickEvent);
        }
    }

    /**
     * Removes all the modal listeners so that activity will not be published
     */
    private removeListeners(): void {
        if (this._modalRootElement) {
            this._modalRootElement.removeEventListener('show.overlay', this.modelShowEvent);
            this._modalRootElement.removeEventListener('hide.overlay', this.modelHideEvent);
        }

        if (this._modalClosePopupButton) {
            this._modalClosePopupButton.removeEventListener('click', this.modalClosePopupButtonClickEvent);
        }

        if (this._modalRefocusPopupButton) {
            this._modalRefocusPopupButton.removeEventListener('click', this.modalRefocusPopupButtonClickEvent);
        }
    }

    private openModel = (modelElement: HTMLElement, backDropElement: HTMLElement) => {
        backDropElement.style.animation = 'none';
        backDropElement.style.opacity = '0.95';
        backDropElement.classList.add('show');

        modelElement.style.animation = 'none';
        modelElement.style.opacity = '1';
        modelElement.style.setProperty('display', 'flex', 'important');
        modelElement.classList.add('show');
    };
    private closeModel = (modelElement: HTMLElement, backDropElement: HTMLElement) => {
        backDropElement.style.animation = 'fadeout half .9s';
        backDropElement.style.opacity = '0';
        backDropElement.classList.remove('show');

        modelElement.style.animation = 'fadeout .9s';
        modelElement.style.opacity = '0';
        modelElement.style.setProperty('display', 'none');
        modelElement.classList.remove('show');
    };

    /**
     * Shows the modal overlay
     * @returns The modal overlay poup
     */
    public show(): void {
        if (this._modalRootElement && this._modalBackDropRootElement) {
            this.openModel(this._modalRootElement, this._modalBackDropRootElement);
            this._modalRootElement.dispatchEvent(this.show_overlay);
        }
    }

    /**
     * Hides the modal overlay
     * @returns The modal overlay popup
     */
    public hide(): void {
        if (this._modalRootElement && this._modalBackDropRootElement) {
            this.closeModel(this._modalRootElement, this._modalBackDropRootElement);
            this._modalRootElement.dispatchEvent(this.hide_overlay);
        }
    }

    /**
     * Cleans up any listeners that may be running in this instance of the overlay
     * to prevent any memory leaks.
     *
     * Also detaches and removes the modal markup from the html document.
     */
    public cleanup(): void {
        this.removeListeners();

        this._modalRootElement?.remove();
        this._modalRootElement = undefined;

        this._modalBackDropRootElement?.remove();
        this._modalBackDropRootElement = undefined;
    }

    /**
     * Replaces the modal body content with loading bars to indicate
     * that a url redirect process is happening
     */
    public displayLoadingBars(): void {
        if (this._modalRootElement) {
            this._modalBodyContent?.classList.add('hidden');
            this._modalBodyLoadingBars?.classList.remove('hidden');
        }
    }

    public get modalRootElement(): HTMLElement | undefined {
        return this._modalRootElement;
    }
}
